import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
		showSearchBox: false,
		showTeleBox: false,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {
		toggleSearchbox: function (event) {
			this.showSearchBox = !this.showSearchBox;
			var inputfeld = document.getElementById("objSearch");
			var btn = document.getElementById("objSearchBtn");
			if (this.showSearchBox) {
				this.showTeleBox = false;
				this.showSettings = false;
				btn.tabIndex = 0;
				inputfeld.focus();
			} else {
				inputfeld.blur();
				btn.tabIndex = -1;
			}
		},
		toggleTelebox: function (event) {
			this.showTeleBox = !this.showTeleBox;
			var telebox = document.getElementById("tele-box");
			var phoneLink = document.getElementById("phoneLink");
			if (this.showTeleBox) {
				telebox.focus();
				phoneLink.tabIndex = 0;
				this.showSearchBox = false;
				this.showSettings = false;
			} else {
				telebox.blur();
				phoneLink.tabIndex = -1;
			}
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

			this.showTeleBox = false;
			this.showSearchBox = false;


		},
		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};