<template>
    <div class="datepicker-trigger">

        <slot></slot>

        <airbnb-style-datepicker :trigger-element-id="triggerId"
            :close-after-select="true"
            :inline="inline"
            :show-shortcuts-menu-trigger="false"
            :show-action-buttons="false"
            :offset-y="10"
            :mode="'range'"
            :fullscreen-mobile="fullscreenMobile"
            :disabled-dates="disabledDays"
            :customized-dates="calClasses"
            :min-date="minDate"
            :end-date="maxDate"
            :date-one="from"
            :date-two="till"
            :months-to-show="months"
            @opened="onOpened"
            @closed="onClosed"
            :mobile-header="mobileHeader"
            @date-one-selected="onFromSelected"
            @date-two-selected="onTillSelected" />


    </div>
</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'

    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'voffice-daterange',
    	voMsgCodes: [
    		'weekdayslist.short',
    		'monthlist.full',
    		'weekdayslist',
    		'tpl.text.from',
    		'tpl.text.nights',
    		'tpl.text.until',
    		'tpl.text.minstay'

    	],
    	data() {
    		return {
    			needTill: false,
    			nextArrivalDate: undefined,
    			disabledArrivalDays: [],
    			disabledDepartureDays: [],
    			calClasses: [],
    			minStayValues: {},
    			priceValues: {},
    			personalSurcharge: undefined,
    			cal: undefined,
    			prices: undefined

    		}
    	},
    	props: {
    		from: String,
    		till: String,
    		triggerId: String,
    		inline: Boolean,
    		fullscreenMobile: Boolean,
    		getCal: Function,
    		months: {
    			type: Number,
    			default: 2
    		},
    		scrollToPricesCalc: {
    			type: Boolean,
    			default: false
    		},
    		getPrices: Function
    	},
    	computed: {
    		disabledDays: function() {
    			return this.needTill ? this.disabledDepartureDays : this.disabledArrivalDays
    		},
    		mobileHeader: function() {
    			if (this.needTill) {
    				return this.voMsg("daterange.till");
    			} else {
    				return this.voMsg("daterange.from");
    			}
    		},
    		minDate: function() {
    			if (this.needTill) {
    				return DateEx.plusDays(this.from, 1);
    			} else {
    				return DateEx.interfaceFormat(DateEx.getToday());
    			}

    		},
    		maxDate: function() {
    			if (this.needTill) {
    				return this.nextArrivalDate;
    			} else {
    				return undefined;
    			}

    		},
    	},

    	methods: {
    		onOpened: function() {
    			if (VOFFICE.settings.showCalendarTooltips && (VOFFICE.settings.showCalendarTooltips.show && VOFFICE.settings.showCalendarTooltips.showprices)) {
    				if (this.getPrices && !this.prices) {
    					this.getPrices((prices) => {
    						this.prices = prices;

    						var from;
    						var till;
    						var diffDays;
    						var days;
    						var day;
    						var priceValues = {};
    						var services = {};
    						var personalSurcharge = null;

    						for (let a of prices.retailPrices) {
    							if (a.service.type === 'RENT' && a.service.surcharge && a.service.mandatory) {
    								if (a.guestRange && a.guestRange.min) {
    									if (!personalSurcharge) {
    										personalSurcharge = a.guestRange.min;
    									}
    								}

    							}
    							for (let b of a.lines) {
    								if (b.type === 'RENT') {
    									from = DateEx.interfaceFormat(b.fromdate);
    									till = DateEx.interfaceFormat(b.tilldate);
    									days = DateEx.getDays(b.tilldate, b.fromdate) - 1;
    									day = DateEx.localDate(b.fromdate);
    									//console.log('Service::', a.service);
    									for (var i = 0; i <= days; i++) {
    										if (!a.service.surcharge) {
    											priceValues[DateEx.interfaceFormat(day)] = {};
    											priceValues[DateEx.interfaceFormat(day)]['price'] = b.value;
    											if (b.nightRange) {
    												priceValues[DateEx.interfaceFormat(day)]['minnights'] = b.nightRange.min
    											}
    										}
    										day = DateEx.plusDays(day, 1)
    									}
    								}
    							}
    						}

    						this.priceValues = priceValues;
    						this.personalSurcharge = personalSurcharge;

    					});

    				}

    			}

    			if (this.getCal && !this.cal) {
    				this.getCal((cal) => {
    					this.cal = cal;
    					var day = DateEx.localDate(cal.availabilityUpdate.split('T')[0]);

    					var arrivals = [];
    					var departures = [];
    					var notAvailable = [];
    					var disabledArrivalDays = [];
    					var disabledDepartureDays = [];
    					var availableArrivalDays = [];
    					var minStayValues = {};
    					var calDays = [];

    					var last;
    					for (let a of cal.availability) {
    						if (a == 'Y') {
    							if (last == 'N' || last == 'Q') {
    								departures.push(DateEx.interfaceFormat(day));
    							}
    						} else {
    							if (last == 'Y') {
    								arrivals.push(DateEx.interfaceFormat(day));
    							} else {
    								notAvailable.push(DateEx.interfaceFormat(day));
    							}
    						}
    						day = DateEx.plusDays(day, 1)
    						last = a;
    					}

    					if (VOFFICE.settings.showCalendarTooltips && (VOFFICE.settings.showCalendarTooltips.show && VOFFICE.settings.showCalendarTooltips.showminstay)) {

    						var day = DateEx.localDate(cal.availabilityUpdate.replaceAll('Z', ''));

    						for (let a of this.cal.minStay) {
    							minStayValues[DateEx.interfaceFormat(day)] = a;
    							day = DateEx.plusDays(day, 1)
    						}

    						this.minStayValues = minStayValues;

    					}

    					this.calClasses = [{
    						cssClass: 'arrival',
    						dates: arrivals
    					}, {
    						cssClass: 'departure',
    						dates: departures
    					}, {
    						cssClass: 'not-available',
    						dates: notAvailable
    					}, {
    						cssClass: 'ar-pos',
    						dates: availableArrivalDays
    					}, {
    						cssClass: 'scope',
    						dates: calDays
    					}, {
    						cssClass: 'no-departure',
    						dates: disabledDepartureDays
    					}, {
    						cssClass: 'no-arrival',
    						dates: disabledArrivalDays
    					}];

    					var day = DateEx.localDate(cal.availabilityUpdate.split('T')[0]);
    					var today = DateEx.localDate(DateEx.getToday());
    					var el = '';

    					for (let a of cal.changeOver) {
    						// no action possible
    						if (a == 'X') {
    							disabledArrivalDays.push(DateEx.interfaceFormat(day));
    						}
    						// O=check-out only    
    						if (a == 'O') {
    							disabledArrivalDays.push(DateEx.interfaceFormat(day));
    						}
    						// C=check-in/out
    						if (a == 'C' || a == 'I') {
    							availableArrivalDays.push(DateEx.interfaceFormat(day));
    						}

    						if (day >= DateEx.plusDays(today, 0)) {
    							calDays.push(DateEx.interfaceFormat(day));
    						}

    						day = DateEx.plusDays(day, 1)
    					}

    					this.disabledArrivalDays = disabledArrivalDays;
    					var priceValues = this.priceValues;
    					this.makeTooltip();

    				});


    			}
    		},
    		onClosed: function() {
    			setTimeout(() => {
    				this.$emit('closed');
    			}, 50);
    		},
    		onFromSelected: function(d) {
    			this.needTill = true;
    			this.$emit('update:from', d);
    			this.$emit('update:till', undefined);

    			if (this.cal) {

    				//nextArrivalDate
    				let day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);
    				let from = DateEx.localDate(d);

    				let nextArrivalDate;
    				for (let a of this.cal.availability) {
    					if (day > from && a != 'Y') {
    						nextArrivalDate = day;

    						break;
    					}
    					day = DateEx.plusDays(day, 1);
    				}

    				this.nextArrivalDate = DateEx.interfaceFormat(nextArrivalDate);

    				day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);

    				var disabledDepartureDays = [];

    				for (let a of this.cal.changeOver) {
    					if (nextArrivalDate && (day > nextArrivalDate)) {
    						disabledDepartureDays.push(DateEx.interfaceFormat(day));
    					} else if (day > from && (a == 'X' || a == 'I')) {
    						disabledDepartureDays.push(DateEx.interfaceFormat(day));
    					}
    					day = DateEx.plusDays(day, 1)
    				}

    				day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);
    				var diffDays;
    				for (let a of this.cal.minStay) {
    					if (nextArrivalDate && (day > nextArrivalDate)) {
    						break;
    					} else if (day > from) {
    						diffDays = DateEx.getDays(day, from);
    						if (diffDays < a) {
    							disabledDepartureDays.push(DateEx.interfaceFormat(day));
    						}
    					}
    					day = DateEx.plusDays(day, 1)
    				}
    				this.disabledDepartureDays = disabledDepartureDays;

    			}

    		},
    		onTillSelected: function(d) {
    			this.needTill = isBlank(d) && this.from;
    			this.$emit('update:till', d);

    			setTimeout(() => {
    				this.$emit('on-tilldate-selected', {
    					scrollToPricesCalc: this.scrollToPricesCalc
    				});
    			}, 50);

    		},

    		makeTooltip: function() {
    			if (VOFFICE.settings && VOFFICE.settings.showCalendarTooltips && (!VOFFICE.settings.showCalendarTooltips.show)) {
    				return;
    			}
    			const that = this;
    			var minStayValues = this.minStayValues;
    			var priceValues = this.priceValues;
    			var personalSurcharge = this.personalSurcharge

    			var minstayText = this.voMsg("tpl.text.minstay");
    			var nightsText = this.voMsg("tpl.text.nights");
    			var fromText = this.voMsg("tpl.text.from");
    			var untilText = this.voMsg("tpl.text.until");
    			var personsText = this.voMsg("tpl.text.persons");

    			$('body').on('mouseover', '.asd__day', function(e) {
    				var pickdate = ''
    				var title = '';
    				var price;
    				var cur = VOFFICE.currency || '&euro;';
    				var currency = cur.replace(/eur/i, "&euro;");
    				pickdate = $(this).attr('data-date');

    				//title = DateEx.formatDate(pickdate, undefined, VOFFICE.mlang);

    				if ($(this).hasClass('asd__day--not-available')) {
    					title += 'bereits belegt';
    				} else {
    					if (minStayValues[pickdate]) {
    						title += minstayText + ": " + minStayValues[pickdate] + ' ' + nightsText;
    					}
    					if (that.priceValues[pickdate]) {
    						price = (Math.round((that.priceValues[pickdate].price / 100) * 100) / 100);
    						if (minStayValues[pickdate]) {
    							title += "<br/>" + price.toLocaleString(undefined, {
    								minimumFractionDigits: 2,
    								maximumFractionDigits: 2
    							}) + ' ' + currency;
    						} else {
    							title += price.toLocaleString(undefined, {
    								minimumFractionDigits: 2,
    								maximumFractionDigits: 2,
    							}) + ' ' + currency;
    						}
    						if (that.priceValues[pickdate].minnights) {
    							title += " " + fromText + " " + that.priceValues[pickdate].minnights + ' ' + nightsText;
    							if (personalSurcharge) {
    								title += "<br/>" + untilText + " " + personalSurcharge + ' ' + personsText;
    							}
    						} else {
    							if (personalSurcharge) {
    								title += " " + untilText + " " + personalSurcharge + ' ' + personsText;
    							}
    						}
    					}
    				}

    				$(this).attr('data-title', title);
    				$(this).attr('data-toggle', 'tooltip');
    				$(this).attr('data-placement', 'top');
    				$(this).tooltip('hide');
    				$(this).tooltip({
    					placement: "top",
    					html: true,
    					template: '<div class="tooltip datepicker" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    				});
    				$(this).tooltip('show');
    			});

    			$('body').on('mouseout click', '.asd__day', function(e) {
    				$(this).tooltip('hide');
    			});

    		}
    	},
    	/*  	watch: {
    	  		from: function(newvalue, oldvalue) {


    	  			console.log('from::', this.from, 'oldvalue::', oldvalue, 'newvalue::', newvalue);
    	  			if (!this.from) {
    	  				this.$emit('update:till', oldvalue);
    	  				this.$emit('on-tilldate-selected');
    	  			}
    	  		}
    	  	}, */
    }
</script>


<style scoped>
    .asd__wrapper--full-screen {
    	z-index: 9999;
    }

    .asd__day-button {
    	pointer-events: none;
    }

    .asd__day {
    	opacity: 1 !important;
    }

    .asd__day--disabled,
    .asd__day--empty {
    	opacity: 1 !important;
    }
</style>